import { AfterViewInit, Component } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { OrganizationNotification } from '@me-fit-mono/typings';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash-es';
import { Observable, combineLatest, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { fadeInOut } from './animations';
import { ConfirmAlert } from './decorators/confirm-alert-decorator';
import { NotificationPopoverDismissEventData } from './modules/components/notifications-popover/notifications-popover.component';
import { AuthService } from './services/auth.service';
import { FeatureFlipService } from './services/feature-flip.service';
import { OrganizationStateService } from './state/organization.state.service';

interface MenuItem {
  i18nLabel: string;
  icon: string;
  path: string;
  hidden$?: Promise<boolean> | Observable<boolean>;
  /** Will show a new badge on desktop */
  isNew?: boolean;
}

export const workoutIcon = 'create-outline';

export const customersIcon = 'person-outline';

export const groupsIcon = 'people-outline';

export const calendarIcon = 'calendar-outline';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [fadeInOut]
})
export class AppComponent implements AfterViewInit {

  hideSidebar$: Observable<boolean> = this.router.events.pipe(
    mergeMap(() => {
      if (this.activatedRoute.firstChild?.data) {
        return of(this.isHideSidebarSet(this.activatedRoute));
      } else {
        // Hide by default to avoid flickering
        return of(true);
      }
    }),
  );

  expandSideMenu: boolean = JSON.parse(localStorage.getItem('sideMenuExpanded') ?? 'false');

  currentOrganization$ = this.$auth.organization$;

  currentOrganizationName$ = this.$auth.organization$.pipe(
    map(org => org?.name)
  );

  currentOrganizationPhotoUrl$ = this.$auth.organization$.pipe(
    map(org => org?.photoUrl)
  );

  firebaseUser$ = user(this.auth).pipe(
    map(user => user)
  );

  currentUser$ = this.$auth.currentEmployee$;

  menuItems: MenuItem[] = [
    {
      i18nLabel: 'workout.plural',
      icon: workoutIcon,
      path: 'workouts'
    },
    {
      path: 'calendar',
      i18nLabel: 'calendar.page.title',
      icon: calendarIcon,
      hidden$: this.$featureFlip.calendarEnabled$.pipe(
        map(isEnabled => !isEnabled)
      ),
    },
    {
      path: 'exercises',
      i18nLabel: 'exercise.list.title',
      icon: 'list-outline',
    },
    {
      path: 'assessments',
      i18nLabel: 'form.list.title',
      icon: 'clipboard-outline',
      hidden$: this.$auth.isSuperAdmin$.pipe(
        map(isSuperAdmin => !isSuperAdmin)
      ),
      isNew: true
    },
    {
      i18nLabel: 'customers',
      icon: customersIcon,
      path: 'customers'
    },
    {
      i18nLabel: 'groups',
      icon: groupsIcon,
      path: 'groups',
    },
    {
      path: 'employees',
      i18nLabel: 'employee.list.title',
      icon: 'business-outline',
    },
    {
      i18nLabel: 'chat',
      icon: 'chatbubbles-outline',
      path: 'chat',
      hidden$: of(true),
    },
    {
      i18nLabel: 'home.screen.settings',
      icon: 'settings-outline',
      path: 'home-screen-settings',
      hidden$: combineLatest([
        this.$auth.isSuperAdmin$,
        this.$featureFlip.isFeatureEnabled('homeScreenSettings')
      ]).pipe(
        map(([isSuperAdmin, isEnabled]) => {
          if (isSuperAdmin) {
            return false;
          }

          let hidden = !isEnabled;

          return hidden;
        })
      )
    }];

  notificationsLoading$ = this.$organizationState.getNotificationsEvents.state$.pipe(
    map(state => {
      return isNil(state.response) || state.isLoading;
    })
  );

  hideNotificationsButton$ = this.$organizationState.getNotificationsEvents.state$.pipe(
    map(state => {
      return !isNil(state.response) && !state.isLoading && state.response?.length === 0;
    })
  );

  constructor(
    private $translate: TranslateService,
    private auth: Auth,
    private router: Router,
    private navCtrl: NavController,
    private $auth: AuthService,
    private menuCtrl: MenuController,
    private platform: Platform,
    private $organizationState: OrganizationStateService,
    private activatedRoute: ActivatedRoute,
    private $featureFlip: FeatureFlipService
  ) {
    this.$translate.use('en');
  }

  ngAfterViewInit(): void {
    this.$auth.fetchCurrentEmployee();

    // Disable menu swiping on ios as it causes issues with navigating back
    if (this.platform.is('ios')) {
      this.menuCtrl.swipeGesture(false, 'mainMenu');
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log('[NavigationEnd]', event, this.activatedRoute);
      }
    });
  }

  async navigateToPage(menuItem: MenuItem) {
    try {
      await this.navCtrl.navigateRoot(menuItem.path);

      this.closeMenu();
    } catch (error) {
      console.error('[AppComponent:navigateToPage] error navigating & closing menu', error);
    }
  }

  @ConfirmAlert({
    i18nConfirmButtonKey: 'action.logout',
    i18nMessageKey: 'logout.message',
    i18nTitleKey: 'action.logout',
    i18nCancelButtonKey: 'action.cancel',
  })
  async logout() {
    try {
      await this.$auth.logout();

      this.closeMenu();
    } catch (error) {
      console.error('[AppComponent:navigateToPage] error signing out, navigating & closing menu', error);
    }
  }

  async closeMenu() {
    try {
      const menus = await this.menuCtrl.getMenus();

      console.log(menus);

      // Incase the menu is already disabled or animating, we need to wait for it to finish
      const isOpen = await menus[0].isOpen();
      console.log('[AppComponent:closeMenu] closing menu isOpen=', isOpen);
      const result = await menus[0].close(false);
      console.log('[AppComponent:closeMenu] menu closed', result);
    } catch (error) {
      console.error('[AppComponent:closeMenu] error closing menu', error);
    }
  }

  async editOrganisation() {
    try {
      await this.router.navigate(['organization/details']);
    } catch {} finally {
      this.menuCtrl.close();
    }
  }

  toggleSideMenuExpand() {
    this.expandSideMenu = !this.expandSideMenu;

    localStorage.setItem('sideMenuExpanded', this.expandSideMenu.toString());
  }

  onNotificationPopoverDismiss(event: Event) {
    const { detail } = event as CustomEvent<{ data: NotificationPopoverDismissEventData }>;
    const { data } = detail;

    if (data?.organizationNotification) {
      console.log('[AppComponent:openNotificationsPopover] data', data);
      this.openNotification(data?.organizationNotification);
    }

    if (data?.showMore) {
      this.navCtrl.navigateForward('notifications');

      this.closeMenu();
    }
  }

  openNotification(notificationEvent: OrganizationNotification) {
    this.navCtrl.navigateForward(notificationEvent.url);

    this.closeMenu();
  }

  isHideSidebarSet(route: ActivatedRoute): boolean {
    // Check if hideSidebar is set in the current route
    if (route.snapshot.data && route.snapshot.data.hideSidebar) {
      return true;
    }

    // Check children routes
    if (route.children && route.children.length > 0) {
      for (const childRoute of route.children) {
        // Recursively check children routes
        if (this.isHideSidebarSet(childRoute)) {
          return true;
        }
      }
    }

    return false;
  }

}
