/** Generated from https://app.quicktype.io/ */
export interface VimeoVideo {
  uri: string;
  name: string;
  description: null;
  type: string;
  /** this is the share link, which the user wants when editing the page */
  link: string;
  duration: number;
  width: number;
  language: null;
  height: number;
  embed: Embed;
  created_time: Date;
  modified_time: Date;
  release_time: Date;
  content_rating: string[];
  license: null;
  privacy: VideosPrivacy;
  pictures: Pictures;
  tags: any[];
  stats: Stats;
  categories: any[];
  metadata: VimeoVideoMetadata;
  user: User;
  parent_folder: ParentFolder;
  last_user_action_event_date: Date;
  review_page: ReviewPage;
  files: Download[];
  download: Download[];
  app: App;
  status: string;
  resource_key: string;
  upload: Upload;
  transcode: Transcode;
  is_playable: boolean;
}

export interface App {
  name: string;
  uri: string;
}

export interface Download {
  quality: Quality;
  type: Type;
  width?: number;
  height?: number;
  expires?: Date;
  link: string;
  created_time: Date;
  fps: number;
  size: number;
  md5: string;
  public_name: string;
  size_short: string;
}

export enum Quality {
  HD = "hd",
  HLS = "hls",
  SD = "sd",
}

export enum Type {
  VideoMp4 = "video/mp4",
}

export interface Embed {
  buttons: Buttons;
  logos: Logos;
  title: Title;
  playbar: boolean;
  volume: boolean;
  speed: boolean;
  color: string;
  uri: null;
  html: null;
  badges: Badges;
}

export interface Badges {
  hdr: boolean;
  live: Live;
  staff_pick: StaffPick;
  vod: boolean;
  weekend_challenge: boolean;
}

export interface Live {
  streaming: boolean;
  archived: boolean;
}

export interface StaffPick {
  normal: boolean;
  best_of_the_month: boolean;
  best_of_the_year: boolean;
  premiere: boolean;
}

export interface Buttons {
  like: boolean;
  watchlater: boolean;
  share: boolean;
  embed: boolean;
  hd: boolean;
  fullscreen: boolean;
  scaling: boolean;
}

export interface Logos {
  vimeo: boolean;
  custom: Custom;
}

export interface Custom {
  active: boolean;
  url: null;
  link: null;
  sticky: boolean;
}

export interface Title {
  name: string;
  owner: string;
  portrait: string;
}

export interface VimeoVideoMetadata {
  connections: PurpleConnections;
  interactions: Interactions;
}

export interface PurpleConnections {
  comments: Albums;
  credits: null;
  likes: Albums;
  pictures: Albums;
  texttracks: Albums;
  related: null;
  recommendations: null;
  albums: Albums;
  available_albums: Albums;
  versions: Versions;
}

export interface Albums {
  uri: string;
  options: Option[];
  total: number;
}

export enum Option {
  Delete = "DELETE",
  Get = "GET",
  Patch = "PATCH",
  Post = "POST",
  Put = "PUT",
}

export interface Versions {
  uri: string;
  options: Option[];
  total: number;
  current_uri: string;
  resource_key: string;
}

export interface Interactions {
  watchlater: Watchlater;
  report: Report;
  view_team_members: Edit;
  edit: Edit;
}

export interface Edit {
  uri: string;
  options: Option[];
}

export interface Report {
  uri: string;
  options: Option[];
  reason: string[];
}

export interface Watchlater {
  uri: string;
  options: Option[];
  added: boolean;
  added_time: null;
}

export interface ParentFolder {
  created_time: Date;
  modified_time: Date;
  last_user_action_event_date: Date;
  name: string;
  privacy: ParentFolderPrivacy;
  resource_key: string;
  uri: string;
  link: null;
  user: User;
  metadata: ParentFolderMetadata;
}

export interface ParentFolderMetadata {
  connections: FluffyConnections;
}

export interface FluffyConnections {
  items: Albums;
  videos: Albums;
  folders: Albums;
}

export interface ParentFolderPrivacy {
  view: string;
}

export interface User {
  uri: string;
  name: string;
  link: string;
  location: string;
  gender: string;
  bio: null;
  short_bio: null;
  created_time: Date;
  pictures: Pictures;
  websites: any[];
  metadata: UserMetadata;
  location_details: LocationDetails;
  skills: any[];
  available_for_hire: boolean;
  can_work_remotely: boolean;
  preferences: Preferences;
  content_filter: string[];
  resource_key: string;
  account: string;
}

export interface LocationDetails {
  formatted_address: string;
  latitude: null;
  longitude: null;
  city: null;
  state: null;
  neighborhood: null;
  sub_locality: null;
  state_iso_code: null;
  country: null;
  country_iso_code: null;
}

export interface UserMetadata {
  connections: TentacledConnections;
}

export interface TentacledConnections {
  albums: Albums;
  appearances: Albums;
  categories: Albums;
  channels: Albums;
  feed: Edit;
  followers: Albums;
  following: Albums;
  groups: Albums;
  likes: Albums;
  membership: Edit;
  moderated_channels: Albums;
  portfolios: Albums;
  videos: Albums;
  watchlater: Albums;
  shared: Albums;
  pictures: Albums;
  watched_videos: Albums;
  folders_root: Edit;
  folders: Albums;
  teams: Albums;
  block: Albums;
}

export interface Pictures {
  uri: string;
  active: boolean;
  type: string;
  sizes: Size[];
  resource_key: string;
  default_picture: boolean;
}

export interface Size {
  width: number;
  height: number;
  link: string;
  link_with_play_button?: string;
}

export interface Preferences {
  videos: Videos;
}

export interface Videos {
  privacy: VideosPrivacy;
}

export interface VideosPrivacy {
  view: string;
  comments: string;
  embed: string;
  download: boolean;
  add: boolean;
}

export interface ReviewPage {
  active: boolean;
  link: string;
}

export interface Stats {
  plays: number;
}

export interface Transcode {
  status: string;
}

export interface Upload {
  status: string;
  link: null;
  upload_link: null;
  complete_uri: null;
  form: null;
  approach: null;
  size: null;
  redirect_url: null;
}

export interface VimeoPaginatedResponse<Item = any> {
  page: number;
  per_page: number;
  total: number;
  data: Array<Item>;
  paging: {
    first: string;
    last: string;
    next: string | null;
    previous: string | null;
  }
}

export interface VimeoVideoUploadError {
  errorType: 'mimetype' | 'fileSize';
  message: string;
}
