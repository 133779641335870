import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { ComponentsModule } from "../components/components.module";
import { DirectivesModule } from "../directives/directives.module";
import { FuseJsModule } from "../fusejs/fusejs.module";
import { PipesModule } from "../pipes/pipes.module";
import { CalendarEventUpsertComponent } from "./calendar-event-upsert/calendar-event-upsert.component";
import { ExerciseFilterModalComponent } from "./exercise-filter-modal/exercise-filter-modal.component";
import { ExerciseImageSelectComponent } from "./exercise-image-select/exercise-image-select.component";
import { MigrateWorkoutToTemplateModalComponent } from "./migrate-workout-to-template-modal/migrate-workout-to-template-modal.component";
import { SelectEmployeeModalComponent } from "./select-employee-modal/select-employee-modal.component";
import { SelectOrganizationModalComponent } from "./select-organization-modal/select-organization-modal.component";
import { WorkoutSelectModalComponent } from "./workout-select-modal/workout-select-modal.component";
import { WorkoutShareConfirmModalComponent } from "./workout-share-confirm-modal/workout-share-confirm-modal.component";
import { WorkoutShareModalComponent } from "./workout-share-modal/workout-share-modal.component";
import { WorkoutsReorderModalComponent } from "./workouts-reorder-modal/workouts-reorder-modal.component";
import { TimerModalComponent } from "./timer-modal/timer-modal.component";
import { CustomFieldCreateModalComponent } from "./custom-field-create-modal/custom-field-create-modal";
import { ShareModalComponent } from "../modals/share-modal/share-modal.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ComponentsModule,
    FuseJsModule,
    PipesModule,
    DirectivesModule,
  ],
  declarations: [
    ExerciseImageSelectComponent,
    WorkoutShareModalComponent,
    WorkoutSelectModalComponent,
    SelectOrganizationModalComponent,
    ExerciseFilterModalComponent,
    SelectEmployeeModalComponent,
    CalendarEventUpsertComponent,
    WorkoutShareConfirmModalComponent,
    MigrateWorkoutToTemplateModalComponent,
    WorkoutsReorderModalComponent,
    TimerModalComponent,
    CustomFieldCreateModalComponent,
    ShareModalComponent,
  ],
  exports: [
    ExerciseImageSelectComponent,
    WorkoutShareModalComponent,
    WorkoutSelectModalComponent,
    SelectOrganizationModalComponent,
    ExerciseFilterModalComponent,
    SelectEmployeeModalComponent,
    CalendarEventUpsertComponent,
    WorkoutShareConfirmModalComponent,
    MigrateWorkoutToTemplateModalComponent,
    WorkoutsReorderModalComponent,
    TimerModalComponent,
    CustomFieldCreateModalComponent,
    ShareModalComponent,
  ],
})
export class ModalsModule { }
