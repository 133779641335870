export interface FormUpsertRequest extends Omit<Form, 'versionNumber' | 'createdAt' | 'fields'> {
  /** Will only be there when we are editing an existing form */
  formId?: string;
  fields: Array<Omit<FormField, 'id'>>;
}

export interface FormResponse extends Form {
  formId: string;
  formVersionId: string;
}

export interface FormListResponse {
  forms: Array<Omit<FormResponse, 'fields'>>;
}

export type FormType = "test" | "questionnaire" | "onboarding";

export interface Form {
  formType: FormType;
  title: string;
  description: string;
  versionNumber: number;
  fields: FormField[];
  createdAt: string;
}

export enum FieldType {
  Numeric = "numeric",
  ShortText = "shortText",
  Slider = "slider",
  Options = "options",
}

export type FormField = BaseField | NumericField | ShortTextField | SliderField | OptionsField;

export type FieldData = NumericField["data"] | ShortTextField["data"] | SliderField["data"] | OptionsField["data"];

export interface BaseField {
  title: string;
  description: string;
  required: boolean;
  fieldType: FieldType;
  data: FieldData;
  id: string;
}

export interface NumericField extends BaseField {
  fieldType: FieldType.Numeric;
  data: {};
}

export interface ShortTextField extends BaseField {
  fieldType: FieldType.ShortText;
  data: {};
}

export interface SliderField extends BaseField {
  fieldType: FieldType.Slider;
  data: {
    minValue: number;
    maxValue: number;
    minLabel: string;
    maxLabel: string;
    /** Whether to show steps or not */
    showSteps: boolean;
  };
}

export interface OptionsField extends BaseField {
  fieldType: FieldType.Options;
  data: {
    options: FormOption[];
  };
}

export interface FormOption {
  label: string;
  value: number;
}

export interface FormSubmissionRequest {
  fieldData: Record<string, string | number | boolean>;
}

export interface FormSubmissionResponse {
  formSubmissionId: string;
}


export type BulkShareFormWithCustomerRequest = {
  formId: string;
  organizationCustomerIds: string[];
}

export type BulkShareFormWithGroupRequest = {
  formId: string;
  organizationGroupIds: string[];
}

export type GetSharedEntitiesResponse = {
  customers: Array<{
    id: string;
  }>;
  groups: Array<{
    id: string;
  }>;
}
